import React from "react";
import Card from "../card/Card";
import  { ReactComponent as SvgComp } from '../../assets/icons/Help.svg';
import Image from '../image/Image'
import './SectionHeader.css';
import { SectionHeaderProps } from '../../interface/interface';

const SectionHeader = ( props: SectionHeaderProps) => {
    let { showHelpIcon=true } = props;
    return(
        <div>
            <Card
                content={ 
                    <div className="secHeader">
                        <div className="contentHeader">
                            <h2>{props.sectionHeaderName} </h2>
                        </div>
                        {showHelpIcon && <div>
                            <Image 
                                SourceImage={SvgComp}
                                // imageName="helpIcon"
                                className="helpIcon"
                            />
                        </div>}
                    </div>
                }
            />
        </div>
    )
}

export default SectionHeader;
