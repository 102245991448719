export const formatDate = ( date:any ) => {
    let datePart = [
        date.getMonth() + 1,
        date.getDate(),
        date.getFullYear()
    ].map((n, i) => n.toString().padStart(i === 2 ? 4 : 2, "0")).join("-");
    let timePart = [
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    ].map((n, i) => n.toString().padStart(2, "0")).join(":");
    return datePart + "_" + timePart;
}

/**
 * @param date 
 * @returns the date in dd-MMM-YYYY format eg: '24-Nov-2024
 */
export const getFormattedDate = (date: any) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate();
    const month = months[date.getMonth()];  // getMonth() returns 0-based month index
    const year = date.getFullYear();
    return `${day}-${month}-${year}`; 
};

/**
 * @returns the date time format example: 20241126123456 for date 2024-11-26 12:34:56
 */
export const getFormattedDateAndTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}${hours}${minutes}${seconds}`; 
}