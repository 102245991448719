import React from "react";
import  './Image.css';
import { ImageProps } from '../../interface/interface';

// Image Component to display images
const Image = (props: ImageProps) => {
    const { SourceImage= null } = props;
    return(
        <div role="img" className={`imageBlock ${props.className ?? ''} ${props.imageName}`} onClick={props.onClick}>
            {SourceImage? <SourceImage /> : ''}
        </div>
    )
}

export default Image;