import React from 'react';
import Image from '../image/Image';
import { ReactComponent as DefaultLogo } from '../../assets/icons/DefaultLogo.svg'
import { ReactComponent as Timer } from '../../assets/icons/Timer.svg';
import { ReactComponent as Menu } from '../../assets/icons/Menu.svg';
import './Header.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

/**
 * Header Component for the Test Taker Interface
 * Based on props.showTimerAndTestTakerId show Test Taker ID and Time which is not needed to display in Avatar screen
 */

const Header = (props:any) => {
    const testTakerId = useSelector((state: RootState) => state.appConfigSlice.testTaker.testTakerId);
    return(
        <div className='headerBlock'>
            <div className='flex'>
                <Image SourceImage={DefaultLogo} />
                { props.showTimerAndTestTakerId && <span className='ttID'><b>Test Taker ID : { testTakerId } </b></span> }
            </div>
            { props.showTimerAndTestTakerId && <div className='clockDiv flex'>
                <Image SourceImage={Timer} />
                <div className='timerText'><b>Introduction</b><span className='grayText'>(not timed)</span></div>
            </div> }
            <div className='flex'>
                <span className='menu'><b>Menu</b></span>
                <Image SourceImage={Menu} />
            </div>
            
        </div>
    )
}
export default Header;
