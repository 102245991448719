import React, { useState } from "react";
import Card from "../../components/card/Card";
import "./LoginScreen.css";
import {
  Container,
  Box,
  FormGroup,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  InputLabel
} from "@mui/material";
import {
  loginTexts,
  errorMessages,
  awsExports,
} from "../../constants/constants";
import Logo from "../../logo.svg";

// Redux state and dispatch hook for accessing and updating the Redux store.
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { changeAccessTokenValue, changeTestTakerUsernameValue, changeTestTaker, changeTestTakerSessionIdValue } from "../../redux/appConfigSlice";

import {
  signIn,
  signOut,
  getCurrentUser,
  fetchAuthSession,
} from "@aws-amplify/auth";

// Configure Amplify in index file or root file
// import { Amplify } from "@aws-amplify/core";
import { Amplify } from "aws-amplify";
import { fetchTestTakerSessionId } from "../../api/authApis";
Amplify.configure(awsExports);

/** 
   *  Code to set the access token and remove all auth_token query param from application URL
  */
  // const dispatch = useDispatch<AppDispatch>();
  // const location = useLocation();
  // const navigate = useNavigate();
  // useEffect(() => {
  //   const setAccessToken = (accessToken: any) => {
  //     dispatch(changeAccessTokenValue(accessToken));
  //     const queryParams = new URLSearchParams(location.search);
  //     if (queryParams.has("auth_token")) {
  //       queryParams.delete("auth_token");
  //       navigate({ search: queryParams.toString() }, { replace: true });
  //     }
  //   };
  //   // If the token is not got from the url
  //   if (!authToken) {
  //     const fetchToken = async () => {
  //       try {
  //         let tempUser = {
  //           username: "7etesn2e69pcb0rt644lsdithp",
  //           password: "qvef2a9l2a6v83opd78sumn0ac3jgr5195091itcfnm1vmjso1l",
  //           client_id: "398gupot35bv41vj8uu7j98lvb",
  //           grant_type: "client_credentials",
  //           scope: ["phone", "email", "openid"],
  //         };
  //         const data = await getToken(tempUser);
  //         console.log("Access Token data: ", data);
  //         setAccessToken(data.access_token);
  //       } catch (error) {
  //         // console.error("Error fetching token:", error);
  //       }
  //     };
  //     fetchToken();
  //   } else {
  //     // If the token is already fetched from the url
  //     setAccessToken(authToken);
  //   }
  // }, []);
  
// Login screen component that displays the Login of the selected options.
const LoginScreen = (props: any) => {
  const dispatch = useDispatch<AppDispatch>();

  const [reqObject, setReqObject] = useState({
    email: "",
    password: ""
  });
  const schemaErrorObj = {
    emailError: false,
    emailErrorText: "",
    passwordError: false,
    passwordErrorText: "",
  };
  const schemaLoginErrorObj = {
    loginError: false,
    loginErrorMessage: "",
  };
  const [errorObj, setErrorObj] = useState(schemaErrorObj);
  const [loginErrorObj, setLoginErrorObj] = useState(schemaLoginErrorObj);
  const [isLoading, setIsLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  /**
   * Styles for the components
   */
  const styleBox = { height: 42, marginTop: "15px" };
  const styleBackdrop = {
    zIndex: 1000,
    color: "#fff0000", //zIndex: (theme) => theme.zIndex.drawer + 1,
  };
  const styleInputLabel = {
    fontSize: "14px",
    marginBottom: "20px",
    marginLeft: "5px",
    fontWeight: "normal",
  };

  // email validation
  // const validateEmail = (email: any) => {
  //   var emailRegex = commonRegex.EMAIL_REGEX;
  //   return emailRegex.test(email);
  // };

  // Fetch the current user session id
  const getTestTakerSessionId = async (testTakerAccessToken: string | undefined) => {
    try {
      // Get the session info from the API for the given testTakerId.
      const uniqueSessionInfo = await fetchTestTakerSessionId({testTakerAccessToken});
      console.log("uniqueSessionInfo: ", uniqueSessionInfo.data);
      if (uniqueSessionInfo) {
          dispatch(changeTestTakerSessionIdValue(uniqueSessionInfo?.data));
      }
    } catch (error) {
      console.error("Error fetching session id:", error);
    }
  };

  // form validation
  const formValidation = () => {
    let errorFlag = false;
    if (reqObject.email === "" && reqObject.password === "") {
      setErrorObj({
        emailError: true,
        emailErrorText: errorMessages.EMPTY_USERNAME,
        passwordError: true,
        passwordErrorText: errorMessages.EMPTY_PASSWORD,
      });
      errorFlag = true;
    } 
    else if (reqObject.email === "") {
      setErrorObj({
        ...errorObj,
        emailError: true,
        emailErrorText: errorMessages.EMPTY_USERNAME,
        passwordError: false,
        passwordErrorText: "",
      });
      errorFlag = true;
    } 
    else if (reqObject.password === "") {
      setErrorObj({
        ...errorObj,
        emailError: false,
        emailErrorText: "",
        passwordError: true,
        passwordErrorText: errorMessages.EMPTY_PASSWORD,
      });
      errorFlag = true;
    }
    // else if (!validateEmail(reqObject.email)) {
    //   setErrorObj({
    //     ...errorObj,
    //     emailError: true,
    //     emailErrorText: errorMessages.INVALID_EMAIL,
    //   });
    //   errorFlag = true;
    // }
    else {
      setErrorObj({
        emailError: false,
        emailErrorText: "",
        passwordError: false,
        passwordErrorText: "",
      });
    }
    return errorFlag;
  };

  // Method to handle the successful login and save the username, userId and token information in redux storage
  const handleLoginSuccess = async () => {
    setShowLogin(false);
    let userDetails = await getCurrentUser();
    let session = await fetchAuthSession();
    let testTakerAccessToken = session?.tokens?.accessToken.toString();
    let tempUserName = userDetails?.signInDetails?.loginId;
    let testTakerId  = userDetails?.userId;
    // Save the access token, testTaker username and testTaker id in Redux storage
    dispatch(changeAccessTokenValue(testTakerAccessToken));
    dispatch(changeTestTakerUsernameValue(tempUserName));
    dispatch(changeTestTaker({testTakerId: testTakerId}));

    // Set testTaker sessionId
    getTestTakerSessionId(testTakerAccessToken);
  };

  // method to call on submit
  const handleSubmit = async () => {
    const loginObj = {
      username: reqObject.email,
      password: reqObject.password,
    };
    setLoginErrorObj({ loginError: false, loginErrorMessage: "" });
    // signout if loggedin already
    try {
      await signOut();
      // console.log("SignOut completed")
    } catch (error) {
      // console.log("SignOut already completed")
    }
    if (!formValidation()) {
      setIsLoading(true);
      signIn(loginObj)?.then(
        (loginResponse: any) => {
          setIsLoading(false);
          if (loginResponse.isSignedIn) {
            handleLoginSuccess();
          } else {
            setLoginErrorObj({
              loginError: true,
              loginErrorMessage: loginResponse.nextStep?.signInStep
            });
          }
        },
        (error: any) => {
          setIsLoading(false);
          setLoginErrorObj({
            loginError: true,
            loginErrorMessage: error.message,
          });
          console.log("Sign in error: ", error);
        }
      );
    }
  };

  // key up event triggered from email and password fields
  const onKeyUp = (event: any) => {
    // if enter is pressed, calling handleSubmit method
    if (event.key === loginTexts.ENTER_TEXT) {
      if (showLogin) {
        handleSubmit();
      }
    }
  };
  return (
    <div className={`flex login_main_container`}>
      <Card
        content={
          <div>
            <Container fixed className="login_container">
              {/* API loader */}
              <Backdrop sx={styleBackdrop} open={isLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>

              <Box
                component="img"
                sx={styleBox}
                alt="Cambridge logo"
                src={Logo}
              />
              <FormGroup>
                <TextField
                  required
                  value={reqObject.email}
                  id="text"
                  type="text"
                  label={loginTexts.USERNAME_LABEL}
                  variant="outlined"
                  margin="normal"
                  error={errorObj.emailError}
                  helperText={errorObj.emailErrorText}
                  onChange={(event) => {
                    setErrorObj({
                      ...errorObj,
                      emailError: !event.target.value,
                      emailErrorText: event.target.value ? "" : errorMessages.EMPTY_USERNAME,
                    });
                    setReqObject({
                      email: event.target.value,
                      password: reqObject.password,
                    });
                  }}
                  onKeyUp={onKeyUp}
                />
                <TextField
                  required
                  value={reqObject.password}
                  id="password"
                  type="password"
                  label={loginTexts.PASSWORD_LABEL}
                  variant="outlined"
                  margin="normal"
                  error={errorObj.passwordError}
                  helperText={errorObj.passwordErrorText}
                  onChange={(event) => {
                    setErrorObj({
                      ...errorObj,
                      passwordError: !event.target.value,
                      passwordErrorText: event.target.value
                        ? ""
                        : errorMessages.EMPTY_PASSWORD,
                    });
                    setReqObject({
                      email: reqObject.email,
                      password: event.target.value,
                    });
                  }}
                  onKeyUp={onKeyUp}
                />
                {loginErrorObj.loginError && (
                  <InputLabel
                    size="small"
                    color="error"
                    style={styleInputLabel}
                    variant="standard"
                    focused
                  >
                    {loginErrorObj.loginErrorMessage}
                  </InputLabel>
                )}
                <Button
                  onClick={handleSubmit}
                  id="signin_btn"
                  variant="contained"
                  size="large"
                >
                  {loginTexts.SIGNIN_BTN_LABEL}
                </Button>
              </FormGroup>
            </Container>
          </div>
        }
        cardClassName="contentGrow"
      />
    </div>
  );
};

export default LoginScreen;