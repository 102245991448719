import React from "react";
import '../testScreen/TestScreen.css';
import ThreeScene from "../../components/animation/ThreeScene";

const AvatarScreen = (props: any) => {
    // Extract the props from the parent component
    const { talkingDuration = 0, mode = "idle" } = props;
    return(
        <div className="animContainer">
            {/* Pass the updated talkingDuration and mode to ThreeScene */}
            <ThreeScene talkingDuration={talkingDuration} mode={mode} />
        </div>
    )
}

export default AvatarScreen;