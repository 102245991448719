import React from "react";
import './ProgressBar.css';
import { ReactComponent as ProgressFontTypeImg} from '../../assets/icons/Stage1.svg';
import { ReactComponent as ProgressContrastModeImg} from '../../assets/icons/Stage2.svg';
import { ReactComponent as ProgressSoundCheckImg} from '../../assets/icons/Stage3.svg';
import { ReactComponent as ProgressMicrophoneCheckImg} from '../../assets/icons/Stage4.svg';
import { ReactComponent as ProgressSepeakerImg} from '../../assets/icons/Stage6.svg';

// Progress Bar Component for ICMST UI
const ProgressBar = ( props:any ) => {
    const { currentIdx } = props;
    let ProgressBarImg = null;
    if( currentIdx === 0) {
        ProgressBarImg = ProgressFontTypeImg
    } else if ( currentIdx === 1) {
        ProgressBarImg = ProgressContrastModeImg
    } else if ( currentIdx === 2) {
        ProgressBarImg = ProgressSoundCheckImg
    } else if ( currentIdx === 3) {
        ProgressBarImg = ProgressMicrophoneCheckImg
    } else if ( currentIdx === 4) {
        ProgressBarImg = ProgressSepeakerImg
    } 
    
    return(
        <div className='progressBar'>
            {ProgressBarImg ? <ProgressBarImg /> : ''}
        </div>
    )
}

export default ProgressBar;
