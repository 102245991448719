import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import PropTypes from 'prop-types';

const ThreeScene = ({ mode, talkingDuration }) => {
  const canvasRef = useRef(null); // Reference to the canvas element
  const requestRef = useRef(); // To keep track of the animation frame request

  useEffect(() => {
    // Scene
    const scene = new THREE.Scene();

    // Set canvas background to white color
    // scene.background = new THREE.Color( 0xffffff );

    // Object
    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff }); // Default color
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    // Sizes
    const sizes = {
      width: 800,
      height: 550,
    };

    // Camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height);
    camera.position.z = 3;
    scene.add(camera);

    // Renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current, // Use the canvas from the ref
    });
    renderer.setSize(sizes.width, sizes.height);

    // Time-tracking variables for "talking" mode
    let startTime = performance.now(); // Start time

    // Mode colors
    const modeColors = {
      idle: 0xaaaaaa, // Gray for idle
      listening: 0x00ff00, // Green for listening
      encouraging: 0xffff00, // Yellow for encouraging
      talking: 0x3498db, // Blue for talking
    };

    // Function to stop the animation
    const stopAnimation = () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current); // Cancel any ongoing animation frame
        requestRef.current = null;
      }
    };

    // Animation loop
    const tick = () => {
      const elapsedTime = (performance.now() - startTime) / 1000; // Total elapsed time

      // Update cube color based on the current mode
      if (mode === "talking" && talkingDuration) {
        // Stop animation after the specified duration only for "talking" mode
        if (elapsedTime >= talkingDuration) {
          console.log(
            "Animation stopped after " + talkingDuration + " seconds."
          );
          return;
        }
      }
      // Change cube color
      material.color.set(modeColors[mode]);

      // Spin the cube
      mesh.rotation.y += 0.01;

      // Render the scene
      renderer.render(scene, camera);

      // Request the next frame
      requestRef.current = window.requestAnimationFrame(tick);
    };

    // Start the animation loop
    tick();

    // Cleanup function when component unmounts or mode/talkingDuration changes
    return () => {
      stopAnimation(); // Stop any ongoing animation
      renderer.dispose(); // Cleanup Three.js renderer
      geometry.dispose(); // Cleanup geometry
      material.dispose(); // Cleanup material
    };
  }, [mode, talkingDuration]); // Depend on mode and talkingDuration

  return (
    <div>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};
ThreeScene.propTypes = {
  mode: PropTypes.oneOf(["idle", "listening", "encouraging", "talking"]),
  talkingDuration: PropTypes.number,
};
export default ThreeScene;
