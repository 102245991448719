import React from 'react';
import './SampleContent.css';

const SampleContent = ( ) => {
    return(
        <div className='sampleScreenBlock'>
            <h5>SECTION 1  Questions 1 – 8</h5>
                <br />
                <span>  Questions 1 – 8 </span>  

                <p>
                    <b>
                        Look at the five advertisements, A-E. <br />
                        Which advertisement mentions the following? <br />
                        Write the correct letter, A-E, in boxes 1-8 on your answer sheet. NB You may use any letter more than once.<br />
                        
                        1      up-to-date teaching systems <br />
                        2      that the institution has been established for a significant time <br />
                        3      examination classes <br />
                        4      that arrangements can be made for activities outside class <br />
                        5      the availability of courses for school students
                    </b> 
                </p>  
        </div>
    )
}

export default SampleContent;
