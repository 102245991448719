import createApiClient, { REACT_APP_ATC_API_URL, REACT_APP_API_URL } from "./apiClient";

// Fetch data from the API using GET method
export const fetchData = async (endpoint: string, headers: any, serviceType: any, params: any = {}): Promise<any> => {
  try {
    let apiClient = createApiClient( serviceType === "atc" ? REACT_APP_ATC_API_URL : REACT_APP_API_URL )
    const response = await apiClient.get(endpoint, headers);
    return response;
  } catch (error) {
    return error;
  }
};

// Post data to the API using POST method
export const postData = async (endpoint: string, data: any, headers: any, serviceType: any): Promise<any> => {
  try {
    let apiClient = createApiClient( serviceType === "atc" ? REACT_APP_ATC_API_URL : REACT_APP_API_URL )
    const response = await apiClient.post(endpoint, data, headers);
    return response;
  } catch (error) {
    return error;
  }
};

