import React from "react";
import './Button.css';

// Button component interface
const Button = ( props: any ) => {
    const { iconLeft = null , iconRight = null, disabled, label, ...other } = props
    return(
        <button className={`button ${props?.buttonclass}`} disabled={disabled} {...other}>
            <div className="imageLeft">{iconLeft}</div>
            <span className="buttonText">{label}</span>
            <div className="imageRight">{iconRight}</div>
        </button>
    )
}

export default Button;
