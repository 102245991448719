import React from 'react';
import { LiveAudioVisualizer, AudioVisualizer} from 'react-audio-visualize';

export interface AudioVisualizerProps { 
    mediaRecorder?: any,
    barWidth?: number,
    barGap?: number,
    barColor?: string,
    height?: number,
    width?: number,
    blob?: any,
}

const ICMSTAudioVisualizer = ( props:AudioVisualizerProps ) => {
    let defaultValueObject:any = {
        width:100,
        height:30,
        barWidth:3,
        gap:4,
        barColor:'black'
    }
    return(
        <div>
            {props?.mediaRecorder && <LiveAudioVisualizer
                mediaRecorder={props?.mediaRecorder}
                {...defaultValueObject}
                {...props}
            />}
            {props?.blob && <AudioVisualizer
                {...defaultValueObject}
                {...props}
            />}
        </div>
    )
}


export default ICMSTAudioVisualizer;