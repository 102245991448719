import axios, { AxiosInstance } from "axios";
export const REACT_APP_API_URL: string =  process.env.REACT_APP_API_URL ?? '';
export const REACT_APP_ATC_API_URL: string = process.env.REACT_APP_ATC_API_URL ?? '';

const createApiClient = ( url: string) => {
  // Create an Axios instance
  const apiClient: AxiosInstance = axios.create({
    baseURL: url
  });

  // Add a request interceptor to include the auth token
  apiClient.interceptors.request.use(
    (config: any) => {
      return config;
    }
  );

  // Add a response interceptor to handle errors
  apiClient.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (error: any) => {
      if (error.response) {
        return error.response.data;
      }
    }
  );
  return apiClient;
}

export default createApiClient;
