import React, { useEffect, useRef, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { AudioRecorderDefaultConfig } from "../../components/audioRecorder/AudioRecorder";
import Card from "../../components/card/Card";
import AvatarScreen from "../avatarScreen/AvatarScreen";
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import './TestScreen.css';
import ICMSTAudioVisualizer from "../../components/audioVisualizer/AudioVisualizer";
import { getContentData, uploadAudioFile } from "../../api/authApis";
import { isSilenceDetectedForGivenTime } from "../../service/MicService";
import { formatDate } from "../../service/utils";
import { 
    animationModes, MAX_DURATION, MIN_DURATION, RULE_TWO_SILENCE_DURATION 
} from "../../constants/constants";
const TestScreen = () => {
    // Settings or configurations needed for taking test
    const { 
        selectedMic, appFontSize, testTaker, silenceMeanValue
    } = useSelector((state: RootState) => state.appConfigSlice);
    const audioContextRef = useRef<AudioContext>();
    const mediaRecorderRef = useRef<MediaRecorder>()
    const destinationRef = useRef<MediaStreamAudioDestinationNode>();
    const [fullRecordingBlob, setFullRecordingBlob] = useState();
    const [currentQuestionInfo, setCurrentQuestionInfo] = useState<any>(null);
    const [ userAudioFileName, setUserAudioFileName ] = useState<any>('');
    const [questionList, setQuestionList] = useState([]);
    const [playIndex, setPlayIndex] = useState(0);
    let timeOutRef = useRef<number | NodeJS.Timeout>()
    let audiRecorder2Ref = useRef<any>();
    const [loading, setLoading] = useState(false);
    let fullRecording = useAudioRecorder({
        ...AudioRecorderDefaultConfig.audioTrackConstraints,
        deviceId: selectedMic || 'default',
    });
    audiRecorder2Ref.current = useAudioRecorder({
        ...AudioRecorderDefaultConfig.audioTrackConstraints,
        deviceId: selectedMic || 'default',
    });

    // Set the audio player state to playing or not playing
    let isAudioPlaying = false;

    // Set the animation mode by default to idle
    const modeRef = useRef<string|undefined>();


    // sets the current question and play the question audio
    useEffect(() => {
        console.log(currentQuestionInfo)
        if (currentQuestionInfo && currentQuestionInfo !== null) {
            playAudio(currentQuestionInfo);
        } else {
            setCurrentQuestionInfo(questionList[playIndex]);
            if( playIndex === questionList.length) {
                stopFullRecording();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestionInfo])


    useEffect(() => {
        if( fullRecordingBlob) {
            // upload the audio to s3
            uploadAudioFile(fullRecordingBlob, ".mp3", `fullrecording`, testTaker);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fullRecordingBlob])

    //fetc content data 
    const fetchContent = async () => {
        setLoading(true);
        let res = await getContentData( testTaker );
        setLoading(false)
        return res;
    }

    // Once the whole test is done , this will get execute
    const stopFullRecording = () => {
        fullRecording?.stopRecording();
        mediaRecorderRef?.current?.stop()
    }

    // stops the user audio and remove the timeout
    const stopRecording = (fromSource: any) => {
        audiRecorder2Ref?.current?.stopRecording();
        clearTimeout(timeOutRef.current);
        let fileName = `${playIndex + 1}_${formatDate(new Date())}`;
        setCurrentQuestionInfo(null);
        setUserAudioFileName(fileName)
        if (playIndex < questionList.length - 1) {
            setPlayIndex(playIndex + 1);
        } else {
            setPlayIndex(playIndex + 1);
            stopFullRecording()
        }
    }

    // playss the audio provided as argument
    const playAudio = (questionInfo: any) => {
        let audioUrl = questionInfo?.localizedMedia?.audioPreSignUrl;
        const audio = new Audio(audioUrl);
        audio.crossOrigin = "anonymous";
        const sourceNode = audioContextRef.current?.createMediaElementSource(audio);
        if (destinationRef.current) {
            sourceNode?.connect(destinationRef.current);
        }
        if (audioContextRef?.current) {
            sourceNode?.connect(audioContextRef?.current?.destination)
        }
        if( questionInfo?.expectedMaxDuration !== 0) { 
            timeOutRef.current = setTimeout(() => { stopRecording('window') }, questionInfo?.expectedMaxDuration ? questionInfo?.expectedMaxDuration * 1000 : MAX_DURATION)
        }
        audio.play()
        .then((res:any) => {
            isAudioPlaying = true;

            // handle talking mode here
            modeRef.current = animationModes.TALKING;
            console.log('Avatar is talking ')
        })
        .catch(error => {
            isAudioPlaying = false;
            modeRef.current = animationModes.IDLE;
            console.log('Avatar is idle ')
            console.log('Playing inside catch', error)
        });
        audio.onended = () => {
            isAudioPlaying = false;
            modeRef.current = animationModes.IDLE;
            console.log('Avatar is idle ')
            if( questionInfo?.expectedMaxDuration !== 0) {
                audiRecorder2Ref?.current.startRecording();
            } else {
                setCurrentQuestionInfo(null)
                setPlayIndex( playIndex + 1)   
            }
        }

    }

    // Frames the question list for part 1  and start the part 1
    const startPart1 = (partData: any) => {
        let questionList: any = [];
        if (partData?.introFrame?.introStatement) {
            questionList?.push(partData?.introFrame?.introStatement)
        }
        if (partData?.introFrame?.prompts) {
            partData?.introFrame?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
            questionList = partData?.introFrame?.prompts
                ? [...questionList, ...partData.introFrame.prompts]
                : questionList;
        }
        if (partData?.frameA?.prompts) {
            partData?.frameA?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
            questionList = partData?.frameA?.prompts ? [...questionList, ...partData.frameA.prompts] : questionList;
        }
        if (partData?.frameB?.prompts) {
            partData?.frameB?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
            questionList = partData?.frameB?.prompts ? [...questionList, ...partData.frameB.prompts] : questionList;
        }
        if (partData?.frameC?.prompts) {
            partData?.frameC?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
            questionList = partData?.frameC?.prompts ? [...questionList, ...partData.frameC.prompts] : questionList;
        }
        if (partData?.introFrame?.outroStatement) {
            questionList?.push(partData?.introFrame?.outroStatement)
        }
        setQuestionList(questionList);
        setCurrentQuestionInfo(questionList[0]);

    }

    // Implement the logic for the TestScreen component here.
    const initialize = async () => {
        fullRecording?.startRecording();
        let contentData = await fetchContent();
        startPart1(contentData?.partOne)
    }

    useEffect(() => {
        initialize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // full recording includes avatar and user audio. On end returns the blob
    useEffect(() => {
        if (fullRecording?.mediaRecorder) {
            audioContextRef.current = new AudioContext();
            destinationRef.current = audioContextRef.current.createMediaStreamDestination();
            const micSource = audioContextRef.current.createMediaStreamSource(fullRecording?.mediaRecorder?.stream);
            micSource.connect(destinationRef.current);
            mediaRecorderRef.current = new MediaRecorder(destinationRef.current.stream);
            mediaRecorderRef.current.ondataavailable = (event: any) => {
                setFullRecordingBlob(event.data)
            };
            mediaRecorderRef.current.start();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [fullRecording?.mediaRecorder])

    // User recording is enabled 
    useEffect(() => {
        let isSilence: any = true;
        // This will be executed on once user recording is started for the prompt
        if (audiRecorder2Ref?.current?.mediaRecorder) {
            let maxDuration = currentQuestionInfo?.expectedMaxDuration ? currentQuestionInfo?.expectedMaxDuration * 1000 : MAX_DURATION;
            let minDuration = currentQuestionInfo?.expectedMinDuration ? currentQuestionInfo?.expectedMinDuration : MIN_DURATION;
                
            const fetchSilence = async () => {
                let i = 0, silentTime = 0,speakingTime = 0;
                // This loop results whether user is speaking or in silent mode for every 1 second to maxDuration
                // loop will get update in future
                // Intent detection can be done inside the loop based on conditions
                while (i * 1000 <= maxDuration && audiRecorder2Ref?.current?.mediaRecorder) {
                    isSilence = await isSilenceDetectedForGivenTime(audiRecorder2Ref?.current, 1000, silenceMeanValue || 0.01544762491032353)
                    if (!isSilence) {
                        // handle listening mode here
                        modeRef.current = animationModes.LISTENING;
                        console.log('Avatar is listening');
                        i++;
                        speakingTime++;
                        silentTime = 0;
                    } else {
                        // handle idle mode here
                        if(modeRef.current !== animationModes.TALKING && !isAudioPlaying){
                            modeRef.current = animationModes.IDLE;
                            console.log('Avatar is idle')
                        }

                        i++;
                        silentTime++;
                    }
                    if( isSilence && speakingTime > minDuration && silentTime > RULE_TWO_SILENCE_DURATION - 1) {
                        break;
                    }
                }
                if (silentTime > 0)
                    console.log(`Silent for ${silentTime} seconds`)
                if (audiRecorder2Ref?.current?.mediaRecorder) {
                    stopRecording('On Voice end');
                }
            }
            fetchSilence()
        }

        // This will execute ones the user recording is done for particular prompt and before the playing
        // the next audio
        if( audiRecorder2Ref?.current?.recordingBlob && !audiRecorder2Ref?.current?.mediaRecorder) {
            let fileBlob = audiRecorder2Ref?.current?.recordingBlob;
            let fileFormat = ".mp3";
            uploadAudioFile(fileBlob, fileFormat, userAudioFileName, testTaker);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audiRecorder2Ref?.current?.mediaRecorder])
    return (
        <div>
            <div className={`testScreen vertical-center`}>
                <div className={`flex fitContentCenterCard`}>
                    {!loading ? (
                        <div>
                            <Card
                                content={
                                    <div>
                                        <AvatarScreen mode={modeRef.current}/>
                                    </div>
                                }
                            />
                            <div className={`flex belowCard`}>
                                <div>
                                    <div className={`Column`}>
                                        <span className={`rightMargin`}>Recording</span>
                                    </div>
                                    <div
                                        data-testid="blinkingDot"
                                        className={
                                            fullRecording?.isRecording
                                                ? `Column blinking-dot ${appFontSize}-dot`
                                                : `Column steady-dot ${appFontSize}-dot`
                                        }
                                    />
                                </div>
                                <div className="fixedHeight35">
                                {
                                    audiRecorder2Ref?.current?.mediaRecorder && (
                                            <ICMSTAudioVisualizer
                                                mediaRecorder={audiRecorder2Ref?.current?.mediaRecorder}
                                            />

                                    )
                                }
                                {
                                    fullRecordingBlob && (
                                        <a rel="noreferrer" href={URL.createObjectURL(fullRecordingBlob)} target="_blank">Download Recording</a>
                                    )
                                }
                                </div>
                               
                            </div>
                        </div>
                    ) : (
                        <div>
                            Loading...
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}

export default TestScreen;