import React from "react";
import Card from "../../components/card/Card";
import SampleContent from "../../components/sampleContent/SampleContent";
import './SummaryScreen.css';
const avatarImgPath = require("../../assets/icons/SummaryAvatar.png");

// Summary screen component that displays the summary of the selected options.
const SummaryScreen = () => {
  return (
    <div className={`flex summaryAvatarContainer`}>
      <Card
        content={
          <div>
            <div className="container">
              <div className="column">
                <SampleContent />
              </div>
              <div className="column finContentCard" >
                <Card
                  content={
                    <>
                      <img className="summaryAvatar" src={avatarImgPath} alt={"Test Taker Avatar"}/>
                      <div className="centerContent"><b>Sam</b>, English</div>
                    </>
                  }
                  cardClassName="contentGrow finContentCard"
                />
              </div>
            </div>
          </div>
        }
        cardClassName="contentGrow"
      />
    </div>
  );
};

export default SummaryScreen;
