import React from 'react';
import Card from '../../components/card/Card';
import RadioGroup from '../../components/radioGroup/RadioGroup';
import SampleContent from '../../components/sampleContent/SampleContent';

// Importing the necessary hooks for Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';

// Redux imports for theme change
import { changeAppTheme } from '../../redux/appConfigSlice';

// Implement contrast selection screen. Implement radio buttons for each contrast mode.
const ContrastSelection = () => {
    const contrastMode = useSelector((state: RootState) => state.appConfigSlice.appTheme);
    const dispatch = useDispatch<AppDispatch>();

    const radioButtonOptions = [
        { id: 'black_on_white', value: 'black_on_white', checked: false, label: 'Black on white'},
        { id: 'white_on_black', value: 'white_on_black', checked: false, label: 'White on black'},
        { id: 'yellow_on_black', value: 'yellow_on_black', checked: false, label: 'Yellow on black'}
    ]

    let onChangeContrast = ( e:any ) => {
        // Redux action to update theme
        dispatch(changeAppTheme(e.target.value));  
    }
    
    return(
        <div className={`flex`}>
            <div>
                <Card
                    content= {
                        <RadioGroup
                            name={"contrastMode"}
                            onChange={onChangeContrast}
                            options={ radioButtonOptions }
                            checkedValue={contrastMode}
                        />
                    }
                />
            </div>
            <Card
                content={
                    <SampleContent />
                }
                cardClassName='contentGrow'
            />
        </div>      
    )
}
export default ContrastSelection;

