import React from "react";
import './RadioGroup.css';
import { RadioGroupProps } from "../../interface/interface";

const RadioGroup = ( props: Readonly<RadioGroupProps> ) => {
    let { options } = props;
    return(
        <div className='radioGroup'>
            {options?.length > 0 && options.map((ele:any, index: any) => (
                <div className={ele.className} key={index + ele.value}>
                    <input 
                        type="radio" 
                        id={ele.id} 
                        name={props.name} 
                        value={ele.value} 
                        onChange={props.onChange}
                        checked={ele.value === props?.checkedValue}
                    /> 
                <label htmlFor={ele.id}>{ele.label}</label>
                </div>
            ))}
        </div>
    )
}

export default RadioGroup;
