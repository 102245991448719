export const REACT_APP_API_URL =  process.env.REACT_APP_API_URL ?? '';
export const REACT_APP_COGNITO_DOMAIN_URL = process.env.REACT_APP_COGNITO_DOMAIN_URL ?? '';
export const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION ?? '';
export const REACT_APP_COGNITO_USERPOOL_ID = process.env.REACT_APP_COGNITO_USERPOOL_ID ?? '';
export const REACT_APP_COGNITO_USERPOOL_CLIENT_ID = process.env.REACT_APP_COGNITO_USERPOOL_CLIENT_ID ?? '';

export const apiUrl = {
  ACCESS_TOKEN_API_URL: REACT_APP_COGNITO_DOMAIN_URL + 'oauth2/token',
  UPLOAD_AUDIO_FILE: "/api/uploadAudio",
  GET_P1_QUESTIONS: "/api/get_p1_questions",
  GET_P3_QUESTIONS: "/getPartThreeContent",
  GET_PROBES: "/getProbes",
  GET_CONTENT_DATA: "/getContent",
  GET_SESSION_ID_URL: "/userSession/uniqueSession"
};

export const awsExports = {
  Auth: {
    region: REACT_APP_COGNITO_REGION,
    Cognito: {
      userPoolId: REACT_APP_COGNITO_USERPOOL_ID,
      userPoolClientId: REACT_APP_COGNITO_USERPOOL_CLIENT_ID,
    },
    oauth: {
      domain: REACT_APP_COGNITO_DOMAIN_URL,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: REACT_APP_API_URL,
      redirectSignOut: REACT_APP_API_URL,
      responseType: "code",
    },
  },
};

export const commonValues = {
  POST: "POST",
  SESSION_ID: "Jun2024",
};

export const commonRegex = {
  EMAIL_REGEX: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
};

export const loginTexts = {
  ENTER_TEXT: "Enter",
  PAGE_TITLE: "Signin with Email",
  INVALID_CREDENTIAL: "Invalid Credential",
  EMAIL_LABEL: "Email Id",
  USERNAME_LABEL: "Username",
  PASSWORD_LABEL: "Password",
  SIGNIN_BTN_LABEL: "Sign in",
  PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  FORGOT_PASSWORD: "Forgot Password",
  BACK: "Back to Login",
  SEND_CODE: "Send Code",
  CODE_SENT: "Code has been sent to your mail id",
  CODE: "Code",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
  RESET_PASSWORD: "Reset Password",
  SUCCESS: "SUCCESS",
  SUCCESS_PASSWORD: "Password has been changed successfully",
  INVALID_CODE: "Entered invalid code",
  CODE_FAILED: "Sending code failed",
  LOGIN_SECTION_NAME: "Test Taker Login"
};

export const errorMessages = {
  EMPTY_EMAIL: "Please enter email id",
  INVALID_EMAIL: "Please enter valid email id",
  EMPTY_USERNAME: "Please enter username",
  EMPTY_PASSWORD: "Please enter password",
  EMPTY_CODE: "Please enter code received in the mail",
  EMPTY_NEW_PASSWORD: "Please enter new password",
  EMPTY_CONFIRM_PASSWORD: "Please enter confirm password",
  MISMATCH_PASSWORD: "New password and confirm password are not matching",
};

export const animationModes = {
  IDLE: "idle",
  LISTENING: "listening",
  ENCOURAGING: "encouraging",
  TALKING: "talking"
}

export const MAX_DURATION = 15000;

export const MIN_DURATION = 0;

export const RULE_TWO_SILENCE_DURATION = 5;

export const RULE_ONE_SILENCE_DURATION = 5;

export const RULE_FOUR_MAX_COUNTER = 1;
