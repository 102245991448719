  
export interface ICMSTAudoRecorderProps {
    config: {
        deviceId?: string,
        groupId?: string
    } 
}

export const AudioRecorderDefaultConfig: any = {
    audioTrackConstraints: {
        echoCancellation: true,
        autoGainControl: true,
        channelCount: 1,
        echoCancellationType: { ideal: " system " },
        noiseSuppression: true,
        googNoiseSuppression: true,
        voiceIsolation: true,
    }
}