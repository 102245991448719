import React from "react";
import Card from "../../components/card/Card";
import "./SoundSelection.css";
import Image from "../../components/image/Image";
import AudioSrc from '../../assets/audio_files/volume-sample.mp3';

// Importing the AudioPlayer component for playing audio files.
import AudioPlayer from "../../audio/audioPlayer";

/**
 * Importing the necessary icons for the SoundSelection component.
 * The Headphones, PlayButton, and ExitButton are imported from the assets/icons folder as React components.
 */
import { ReactComponent as Headphones } from "../../assets/icons/Headphones.svg";
import { ReactComponent as PlayButton } from "../../assets/icons/PlayButton.svg";
import { ReactComponent as ExitButton } from "../../assets/icons/ExitButton.svg";
import { ReactComponent as PauseButton } from "../../assets/icons/PauseButton.svg";

/**
 * @returns It will return a SoundSelection component.
 * Test Taker test headphones sound selection.
 * The SoundSelection component displays a play/pause button, an exit button, and a slider for audio level adjustment.
 * The play/pause button changes based on the current audio state.
 * The Headphone and exit buttons do nothing.
 */

const SoundSelection = () =>{
  /**
   * @param e the event object containing the new audio level value.
   * It updates the audio level in redux and sets the new audio level value in the state.
   */
  // const audioLevel = useSelector((state: RootState) => state.appConfigSlice.appAudioLevel);
  // const dispatch = useDispatch<AppDispatch>();
  // let onChangeAudioLevel = (e: any) => {
  //   dispatch(changeAppAudioLevel(e.target.value));
  // };

  /**
   * @param value as the new audio file for playAudioAsync, it will play the audio file
   */
  const audioPlayer = AudioPlayer(AudioSrc);

  /**
   * @returns The SoundSelection component with play/pause button, exit button, and audio level slider.
   */
  return (
    <div className={`flex soundBlockNew`}>
      <Card
        content={
          <>
            <div className="container">
              <div className="column">
                <p>
                  <b>Put the headphones on and make sure they fit.</b>
                </p>
              </div>
              <div className="column">
                <p>
                  <b>
                    Click this PLAY button. If you cannot hear the sound, change
                    the volume on your device or the slider below.
                  </b>
                </p>
                <p>
                  <b>Click on the PLAY again if needed.</b>
                </p>
              </div>
              <div className="column">
                <p>
                  <b>
                    Once you are happy with the sound, click NEXT to continue.
                  </b>
                </p>
              </div>
            </div>
            <div className="container">
              <div className="column">
                <Image SourceImage={Headphones} />
              </div>
              <div className="column" data-testid="play-image" role="presentation" onClick={audioPlayer.playAudio} >
                <Image SourceImage={!audioPlayer.isPlaying ? PlayButton : PauseButton} className="middleSvg"/>
              </div>
              <div className="column">
                <Image SourceImage={ExitButton} />
              </div>
            </div>
          </>
        }
        cardClassName="contentGrow"
      />
    </div>
  );
};

export default SoundSelection;