import React from "react";
import './Card.css';
import { CardProps } from "../../interface/interface";

// Card component interface
const Card = (props: Readonly<CardProps>) => {
    const  { content, cardClassName } = props;
    return(
        <div className={` cardBlock ${cardClassName ?? ''}`}>
            {content}
        </div>
    )
}

export default Card;