import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppconfigState } from '../interface/interface';
 
const initialState: AppconfigState = {
    testTaker: {
      testTakerId: "1234567890",
      testTakerName: "Test Taker 1",
      testTakerUsername: "TestTaker_Username",
      testTakerCenterName: "London",
      testTakerLanguage: "en_ca",
      testTakerAccessToken: "",
      testTakerSessionId: ""
    },
    appTheme: 'black_on_white',
    appFontSize: 'small',
    appAudioLevel: 100,
    appMicAccess: false,
    selectedMic: '',
    silenceMeanValue: 0
};
 
export const appConfigSlice = createSlice({
  name: 'appConfigSlice',
  initialState,
  reducers: {
    changeTestTaker: (state, action: PayloadAction<any>) => {
      state.testTaker = {...state.testTaker, ...action.payload};
    },
    changeAppFontSize: (state, action: PayloadAction<string>) => {
      state.appFontSize = action.payload;
    },
    changeAppTheme: (state, action: PayloadAction<string>) => {
        state.appTheme = action.payload;
    },
    changeAppAudioLevel: (state, action: PayloadAction<number>) => {
      state.appAudioLevel = action.payload;
    },
    changeAppMicAccess: (state, action: PayloadAction<boolean>) => {
      state.appMicAccess = action.payload;
    },
    changeSelectedMic:  (state, action: PayloadAction<any>) => {
      state.selectedMic = action.payload;
    },
    changeSilenceValue: (state, action: PayloadAction<any>) => {
      state.silenceMeanValue = action.payload;
    },
    changeAccessTokenValue: (state, action: PayloadAction<any>) => {
      state.testTaker.testTakerAccessToken = action.payload;
    },
    changeTestTakerUsernameValue: (state, action: PayloadAction<any>) => {
      state.testTaker.testTakerUsername = action.payload;
    },
    changeTestTakerSessionIdValue: (state, action: PayloadAction<any>) =>{
      state.testTaker.testTakerSessionId = action.payload;
    }
  },
});
 
export const { 
  changeAppFontSize, changeAppTheme, changeAppAudioLevel, changeAppMicAccess,
  changeSelectedMic, changeSilenceValue, changeTestTaker, changeAccessTokenValue, changeTestTakerUsernameValue,
  changeTestTakerSessionIdValue
} = appConfigSlice.actions;

export default appConfigSlice.reducer;