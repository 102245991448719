import React, { useEffect, useState } from 'react';
import Card from '../../components/card/Card';
import { RootState, AppDispatch } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { changeSilenceValue } from '../../redux/appConfigSlice';
import SilenceCheckScreen from './SilenceCheckScreen';
import SpeakCheckScreen from './SpeakCheckScreen';
import FullNameScreen from './FullNameScreen';


const MicrophoneCheckScreen = (props: any) => {
    const { selectedMic, silenceMeanValue }  = useSelector((state: RootState) => state.appConfigSlice);
    const dispatch = useDispatch<AppDispatch>();

    const [isTimerFinished, setIsTimerFinished] = useState(false);
    const [isSpeakCheckFinished, setIsSpeakCheckFinished] = useState(false);
    const [isFullNameFinished, setIsFullNameFinished] = useState(false);

    const setMeanValue = (meanValue: any) => {
        dispatch(changeSilenceValue(meanValue));
    }

    useEffect(() => {
        if( isFullNameFinished) {
            props.setNextButtonDisabled(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullNameFinished]);
    return (
        <div className='micBlock'>
            <Card
                content={
                    <div className='micCard'>
                        {!isTimerFinished
                            ? <SilenceCheckScreen
                                setIsTimerFinished={setIsTimerFinished}
                                setMeanValue={setMeanValue}
                                selectedMic={selectedMic}
                                prevButtonClicked={props.prevButtonClicked}
                                setPrevCallBackDone={props.setPrevCallBackDone}
                            /> : ''}
                        
                        {isTimerFinished && !isSpeakCheckFinished
                            ? <SpeakCheckScreen
                                silenceMeanValue={silenceMeanValue}
                                selectedMic={selectedMic}
                                setIsSpeakCheckFinished={setIsSpeakCheckFinished}
                                prevButtonClicked={props.prevButtonClicked}
                                setPrevCallBackDone={props.setPrevCallBackDone}
                            /> : ''}
                        
                        {isSpeakCheckFinished && !isFullNameFinished
                            ? <FullNameScreen
                                silenceMeanValue={silenceMeanValue}
                                setMeanValue={setMeanValue}
                                selectedMic={selectedMic}
                                setIsFullNameFinished={setIsFullNameFinished}
                                prevButtonClicked={props.prevButtonClicked}
                                setPrevCallBackDone={props.setPrevCallBackDone}
                            /> : ''}
                        {isFullNameFinished
                            ? 
                            (
                                <div>
                                    <p>Thank you . Your microphone is working fine</p>
                                    <b>Press Next button to move to the next part</b>
                                </div>

                            )
                             : ''}
                    </div>
                }
            />
        </div>
    )
};

export default MicrophoneCheckScreen;
