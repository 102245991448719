const _ = require('lodash');


const calculateRMS = ( arrayData: any) => {
    let squareValue = _.reduce( arrayData, (rmsValue:any , ele:any) => rmsValue + (ele * ele), 0.0);
    return Math.sqrt(squareValue / arrayData.length)

}

export const detectSilence = async (audioBlob?: any, silenceMeanValue?: any) => {
    if (audioBlob) {
        const audioContext = new (window.AudioContext)();
        const arrayBuffer = await audioBlob.arrayBuffer();
        // Decode the audio data
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        const samples = audioBuffer.getChannelData(0);
        let filterData = _.filter(samples, (v:any) => v >= silenceMeanValue);
        filterData.sort((a:any, b:any) => a - b)
        let index = _.clamp(filterData.length * .35, 0, filterData.length - 1);
        let voiceThreshold =  filterData[Math.round(index)];
        console.log('Voice Threshold: ', Math.round(index), voiceThreshold,silenceMeanValue);

        return voiceThreshold > (silenceMeanValue * 3);
    } else {
        console.log('No audio data available.');
    }
}


export const fetchMeanValue = async (audioBlob?: any) => {
    if (audioBlob) {
        const audioContext = new (window.AudioContext)();
        const arrayBuffer = await audioBlob.arrayBuffer();
        // Decode the audio data
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        const samples = audioBuffer.getChannelData(0);
        let filteredVoiceData =_.filter(samples, (value: any) => value > 0);
        let meanValue = calculateRMS(filteredVoiceData);
        console.log('Mean value: ', meanValue);
        return meanValue;

    } else {
        console.log('No audio data available.');
    }
}


export const isSilenceDetectedForGivenTime = ( audioRecorder: any, time: any, silenceThreshold: any ) => {
    if( audioRecorder?.mediaRecorder) {
        const audioContext = new AudioContext();
        const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(audioRecorder?.mediaRecorder?.stream);
        const analyserNode = audioContext.createAnalyser();
        mediaStreamAudioSourceNode.connect(analyserNode);
        const pcmData = new Float32Array(analyserNode.fftSize);
        let audioData:any = [];
        const onFrame = () => {
            analyserNode.getFloatTimeDomainData(pcmData);
            let resultValue = calculateRMS( pcmData)
            audioData.push(resultValue);
        }
        let intervalId = window.setInterval(() => {
            onFrame()
        },100);

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(checkForSilence(audioData, silenceThreshold));
                clearInterval( intervalId)
            }, time)
        })
    }
}

const checkForSilence = (audioData: any, silenceThreshold: any ) => {
    let isSilence = false;
    let talkingData =  _.filter(audioData, (ele: any) =>  ele > silenceThreshold);
    if( talkingData.length > Math.floor(audioData.length * .30) ) {
        return isSilence
    } else {
        isSilence = true;
        return isSilence;
    }

}