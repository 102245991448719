import React, { useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import { AudioRecorderDefaultConfig } from '../../components/audioRecorder/AudioRecorder';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import ICMSTAudioVisualizer from '../../components/audioVisualizer/AudioVisualizer';
import { uploadAudioFile } from '../../api/authApis';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { isSilenceDetectedForGivenTime } from '../../service/MicService';

const FullNameScreen = (props: any) => {
    const testTakerDetails = useSelector((state: RootState) => state.appConfigSlice.testTaker);
    const [isRecordingStarted, setIsRecordingStarted] = useState(false);
    const [errorScreen, setErrorScreen] = useState(false);
    const [ uploadBlob , setUploadBlob ] = useState(false);

    let audioRecorder = useAudioRecorder({
        ...AudioRecorderDefaultConfig,
        deviceId: props?.selectedMic || 'default',
    });

    const stop = async () => { 
        audioRecorder.stopRecording();
    }

    useEffect(() => {
        if( props.prevButtonClicked) {
            stop();
            props.setPrevCallBackDone(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ props.prevButtonClicked])

    useEffect(() => {
        if( audioRecorder?.recordingBlob && uploadBlob) {
            let fileBlob = audioRecorder.recordingBlob? audioRecorder.recordingBlob : new Blob(audioRecorder.recordingBlob);
            let fileFormat = ".mp3";
            let fileName = "fullname";
            uploadAudioFile(fileBlob, fileFormat, fileName, testTakerDetails);   
            props?.setIsFullNameFinished(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRecorder?.recordingBlob])

    useEffect(() => {
        if (!isRecordingStarted && audioRecorder?.recordingTime > 0) {
            audioRecorder?.stopRecording()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecordingStarted]);

    useEffect(() => {
        const fetchData = async () => {
            if( audioRecorder?.mediaRecorder ) {
                let output = await isSilenceDetectedForGivenTime(audioRecorder, 3000, props?.silenceMeanValue);
                if (!output) {
                    setUploadBlob(true)
                    onStop()
                } else {
                    setErrorScreen(true)
                    setUploadBlob(false)
                    onStop()
                }
                console.log('output: ', output);
            }
        };
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRecorder?.mediaRecorder]);

const onStart = async () => {
    setIsRecordingStarted(true);
    audioRecorder?.startRecording()
}

const onStop = async () => {
    setIsRecordingStarted(false);
}

return (
    <>
        { errorScreen ?
            (
                <div>
                    <p>
                        We can't hear you . 
                    </p>
                    <p>
                        This may be because you are speaking too quietly, or because there is too much noise around you.
                    </p>
                    <b>Please move closer to the microphone or try speaking more loudly.</b>
                    <div className='buttonBlk'>
                        <Button
                            label="Try Again"
                            onClick={() => setErrorScreen(false)}
                        />
                    </div>
                </div>
            )
            :
            (<div>
                <p>
                    Thank you we need to do a final check
                </p>
                <b>
                    Press 'Start' button and SAY YOUR FULL NAME
                </b>
                <div className='buttonBlk'>
                    <Button
                        label={isRecordingStarted
                            ? <ICMSTAudioVisualizer
                                mediaRecorder={audioRecorder?.mediaRecorder}
                                barColor={'#ffffff'}
                            />
                            : 'Start'}
                        buttonclass={isRecordingStarted? 'btn_brown' : 'button'}
                        onClick={onStart}
                    />
                </div>
            </div>)
        }
    </>
)
}


export default FullNameScreen;
