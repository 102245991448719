// getHeader function to add token to request headers.
export const getHeader = () => {
  return {
    "Content-Type": "application/json",
  }
}

// postHeader function to add token to request headers.
export const postHeader = (format: any) => {
  return {
    'Content-Type': (format || 'audio/webm'),
    'Accept': (format || 'audio/webm')
  }
}

export const uploadAudioFileDetails = (blob: Blob, format: any, fileName: string, testTakerDetails: any) => {
  // Create a FormData object
  const formData = new FormData();
  formData.append('file', blob, testTakerDetails?.testTakerId + "_" + fileName + format);
  formData.append('testTakerId', testTakerDetails?.testTakerId);
  formData.append('uniqueSessionId', testTakerDetails?.testTakerSessionId);
  return formData;
};

