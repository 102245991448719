import Footer from "../components/footer/Footer";
import React, { useEffect, useState } from "react";
import SectionHeader from "../components/sectionHeader/SectionHeader";
import ProgressBar from "../components/progressBar/ProgressBar";
import FontSelection from "../screens/fontSelection/FontSelection";
import ContrastSelection from "./contrastSelection/ContrastSelection";
import SoundSelection from "./soundSelection/SoundSelection";
import MicrophoneSelectionMain from "./microphoneSelection/MicrophoneSelectionMain";
import MicrophoneCheckScreen from "./microphoneSelection/MicrophoneCheckScreen";
import SummaryScreen from "./summaryScreen/SummaryScreen";

const PersonalizationScreen = ( props: any ) => {
  const [currentIdx, setCurrentIdx] = useState(0); 
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [prevButtonClicked, setPrevButtonClicked] = useState(false);
  const [prevCallBackDone, setPrevCallBackDone] = useState(false);
  let indexHavingPrevCallBack = [4];

  useEffect(() => {
    if (
      (indexHavingPrevCallBack.includes(currentIdx) && prevCallBackDone) ||
      (prevButtonClicked && !indexHavingPrevCallBack.includes(currentIdx))
    ) {
      setCurrentIdx(currentIdx - 1);
      setNextButtonDisabled(false);
      setPrevButtonClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevButtonClicked, prevCallBackDone]);

  let onClickPrev = () => {
    setPrevButtonClicked(true);
  };

  let onClickNext = () => {
    setCurrentIdx(currentIdx <= 4 ? currentIdx + 1 : props?.setPreChecksDone(true));
  };

  let path = [
    {
      sectionName: "Choose your font size",
      index: 0,
      Component: <FontSelection />,
    },
    {
      sectionName: "Choose your colour mode",
      index: 1,
      Component: <ContrastSelection />,
    },
    {
      sectionName: "Check the sound",
      index: 2,
      Component: <SoundSelection />,
    },
    {
      sectionName: "Choose the microphone",
      index: 3,
      Component: (
        <MicrophoneSelectionMain
          setNextButtonDisabled={setNextButtonDisabled}
          onClickNext={onClickNext}
          prevButtonClicked={prevButtonClicked}
          setPrevCallBackDone={setPrevCallBackDone}
        />
      ),
    },
    {
      sectionName: "Choose the microphone",
      index: 3,
      Component: (
        <MicrophoneCheckScreen
          setNextButtonDisabled={setNextButtonDisabled}
          prevButtonClicked={prevButtonClicked}
          setPrevCallBackDone={setPrevCallBackDone}
        />
      ),
    },
    {
      sectionName: `Are these settings correct? If yes, click 'Start the test'.`,
      index: 4,
      Component: <SummaryScreen />,
    },
  ];

  let currentComponent = path[currentIdx];
  return (
    <div>
      <div className="scrollSection">
        <div className={`centerBlock`}>
          <SectionHeader sectionHeaderName={currentComponent["sectionName"]} />
          <ProgressBar currentIdx={currentComponent.index} />
          {currentComponent.Component}
        </div>

          <Footer
            onClickPrev={onClickPrev}
            prevIconDisabled={currentIdx === 0}
            onClickNext={onClickNext}
            nextIconDisabled={nextButtonDisabled}
            nextBtnTitle={
              currentIdx === path.length - 1 ? "Start the test" : undefined
            }
          />
      </div>
    </div>
  );
};

export default PersonalizationScreen;
