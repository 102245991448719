import React, { useEffect, useState } from 'react';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { AudioRecorderDefaultConfig } from '../../components/audioRecorder/AudioRecorder';
import Button from '../../components/button/Button';
import { fetchMeanValue } from '../../service/MicService';



const SilenceCheckScreen = (props: any) => {
    const [buttonLabel, setButtonLabel] = useState(5);
    const [isSilenceFinished, setIsSilenceFinished] = useState(false);

    let audioRecorder = useAudioRecorder({
        ...AudioRecorderDefaultConfig,
        deviceId: props?.selectedMic || 'default',
    });

    useEffect(() => {
        if( audioRecorder.recordingBlob && !audioRecorder.isRecording ) {
            fetchMeanValue(audioRecorder.recordingBlob)
            .then((mValue: any) => {
                props.setMeanValue(mValue);
                finishPage()
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[audioRecorder.isRecording, audioRecorder.recordingBlob, props])

    useEffect(() => {
        if (isSilenceFinished) {
            audioRecorder?.stopRecording();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSilenceFinished, props]);

    const stop = async () => { 
        audioRecorder.stopRecording();
    }

    useEffect(() => {
        if( props.prevButtonClicked) {
            stop();
            props.setPrevCallBackDone(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ props.prevButtonClicked])

    const finishPage = () => { setTimeout(() => props?.setIsTimerFinished(true), 1); }

    /* Start the timer */
    const startCounter = async () => {
        audioRecorder.startRecording(); // Start recording before timer starts
        let interval = setInterval(() => {
            setButtonLabel((currLabel) => {
                if (currLabel !== 1) {
                    return currLabel - 1;
                } else {
                    setIsSilenceFinished(true);
                    clearInterval(interval);
                }
                return 0;
            });
        }, 1000);
    };

    return (
        <div>
            <p>
                First , let us check the sound in room</p>
            <b>Press the Start button, then stay quiet for 5 seconds</b>
            <b><p><u>Please dont say anything during this time</u></p></b>
            <div className='buttonBlk'>
                <Button
                    label={buttonLabel === 5 ? 'Start' : buttonLabel}
                    onClick={buttonLabel === 5 ? startCounter : ()=>{}}
                />
            </div>
        </div>
    )

}

export default SilenceCheckScreen;