import React, { useState } from "react";
import PersonalizationScreen from "./screens/PersonalizationScreen";
import "@fontsource-variable/mulish";
import "./App.css";
import "./common.css";

// Importing the necessary hooks for Redux
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import Header from "./components/header/Header";
import LoginScreen from "./screens/loginScreen/LoginScreen";
import SectionHeader from "./components/sectionHeader/SectionHeader";
import { loginTexts } from "./constants/constants";
import TestScreen from "./screens/testScreen/TestScreen";

const App = () => {
  const [ preChecksDone, setPreChecksDone ] = useState(false)
  const appFontSize = useSelector(
    (state: RootState) => state.appConfigSlice.appFontSize
  );
  const appTheme = useSelector(
    (state: RootState) => state.appConfigSlice.appTheme
  );
  const testTakerAccessToken = useSelector(
    (state: RootState) => state.appConfigSlice.testTaker.testTakerAccessToken
  );
  return (
    <div className={`App`}>
      <div className={`appBlock ${appTheme} ${appFontSize}`}>
        { !testTakerAccessToken && 
          <div className={`centerBlock`}>
            <SectionHeader sectionHeaderName={loginTexts.LOGIN_SECTION_NAME} />
            <LoginScreen />
          </div>
        }
        { testTakerAccessToken && !preChecksDone ? (
          <>
            <Header showTimerAndTestTakerId={true}/>
            <PersonalizationScreen 
              setPreChecksDone={setPreChecksDone}
            />
          </>
         ):""}
         { testTakerAccessToken && preChecksDone ?(
          <>
            <Header/>
            <TestScreen />
          </>
          ):""}
      </div>
    </div>
  );
};

export default App;
