/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Card from '../../components/card/Card';
import './MicrophoneSelection.css';
import Button from '../../components/button/Button';
import MicrophoneSelectionScreen from './MicrophoneSelectionScreen';
// Importing the necessary hooks for Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { changeAppMicAccess } from '../../redux/appConfigSlice';

// Display the proper error message while accessing the microphone.
const getErrorMessage = (errObj: any) => {
    if (errObj.name === 'NotAllowedError' || errObj.message === 'NotAllowedError') {
        return "Microphone access denied by the user. Cannot procced with test";
    } else if (errObj.name === 'NotFoundError' || errObj.message === 'NotFoundError') {
        return "No microphone device found.";
    } else {
        return "Error accessing the microphone: " + errObj?.errorMessage;
    }
}


/* Main component for the microphone selection screen */
export default function MicrophoneSelectionMain( props:any ) {

    const { appMicAccess } = useSelector((state: RootState) => state.appConfigSlice);
    const dispatch = useDispatch<AppDispatch>();

    const [micAccess, setMicAccess] = useState(appMicAccess);
    const [micAccessErrorMsg, setMicAccessErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)

    /* Check whether user has provided permission for microphones */
    function checkMicrophoneAccess() {
        window.navigator?.mediaDevices?.getUserMedia({ audio: true })
            .then(function (stream) {
                setMicAccess(true);
                dispatch(changeAppMicAccess(true));
                stream.getTracks().forEach(track => track.stop());
            })
            .catch(function (err) {
                // Handle errors
                setLoading(false)
                dispatch(changeAppMicAccess(false));
                setMicAccessErrorMsg(err);
            });
    }

    useEffect(() => {
        if (!appMicAccess) {
            setLoading(true)
            checkMicrophoneAccess()
        }
        props.setNextButtonDisabled( true );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='micBlock'>
            <Card
                content={
                    <div className='micCard'>
                        {micAccess
                            ? <MicrophoneSelectionScreen
                                setNextButtonDisabled={props.setNextButtonDisabled}
                                onClickNext={props.onClickNext}
                                prevButtonClicked={props.prevButtonClicked}
                                setPrevCallBackDone={props.setPrevCallBackDone}
                            />
                            : <MicrophoneErrorScreen
                                errorMessage={micAccessErrorMsg}
                                checkMicrophoneAccess={checkMicrophoneAccess}
                                isMicAccessEnabled={appMicAccess}
                                loading={loading}
                            />}
                    </div>
                }
            />
        </div>
    )
}

/* Display an error message when the user denies microphone access */
export const MicrophoneErrorScreen = (props: any) => {
    const { isMicAccessEnabled, loading } = props;
    let returnDiv;
    if (loading) {
        returnDiv = (
            <div>
                <p>Please wait while we are checking your microphone access</p>
                <Button label={'Checking permission...'} />
            </div>
        )
    }
    return (
        <div>
            {
                <div>
                    <h2>Checking microphone access</h2>
                    {returnDiv}
                    {
                        !loading && !isMicAccessEnabled ? (
                            <div>
                                <b>Please allow access to your microphone</b>
                                <p>After providing access please refresh the page</p>
                                {props.errorMessage && <b>{getErrorMessage(props.errorMessage)}</b>}
                            </div>
                        ) : ''
                    }
                </div>

            }

        </div>
    )
}