
import React from 'react';
import './Footer.css';
import Button from '../button/Button';
import Image from '../image/Image';
import { ReactComponent as  ChevronLeft } from '../../assets/icons/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/ChevronRight.svg';

// Footer component that renders Previous and Next buttons based on given props.
const Footer = ( props: any) => {
    return(
        <div className='footerBlock'>
            <Button
                label={'Previous'}
                disabled={props.prevIconDisabled}
                iconLeft={
                    <Image
                        SourceImage={ChevronLeft}
                        imageName='left'
                    />
                }
                buttonclass={'secondary'}
                onClick={props.onClickPrev}
            />
            <Button
                label={props.nextBtnTitle ? props.nextBtnTitle : 'Next'}
                iconRight={
                    <Image
                        SourceImage={ChevronRight}
                        imageName='right'
                    />
                }
                disabled={props.nextIconDisabled}
                onClick={props.onClickNext}
            />
        </div>
    )
}

export default Footer;
